import 'polyfills';
import { processSilentRenew } from 'redux-oidc';

/*
 * We use code splitting to reduce our bundle size here. When the app is at location /signin-silent we assume
 * the app has been loaded into an iframe in order to process a silent token refresh. In this case we don't want to load
 * The entire app as this would be expensive and unessecary.
 */
if (window.location.pathname === '/signin-silent') {
  processSilentRenew();
} else {
  import('./App').then(App => {
    App.default();
  });
}
